var $class="se2--header-se--common-part",$name="HeaderSE/commonPart",$path="app/components/HeaderSE/partials/commonPart/index.js",$this={$class,$name,$path,};import trimEnd from 'lodash/trimEnd';
import { headerClassName, ariaExpandedAttribute } from 'app/components/HeaderSE/config';
import appendUTMParametersHeader from 'app/components/utilities/appendUTMParametersHeader';

// eslint-disable-next-line max-len
import redefineTargetAttributeOfSustainabilityLink from 'app/components/utilities/redefineTargetAttributeOfSustainabilityLink';

import shell from 'app/modules/shell';

import { isHeaderVersion8 } from 'app/components/HeaderSE/utilities/isHeaderVersion8';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import isUsingKeyboard from 'app/utilities/isUsingKeyboard';

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { css, messages, delays } from 'configs';

import first from 'lodash/first';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import last from 'lodash/last';
import map from 'lodash/map';
import getCookie from 'app/utilities/getCookie';
import ajax from 'app/modules/ajax';
import { countrySelectorSEClassName } from 'app/components/HeaderSE/deferrals/countrySelectorModal/config';
import { menuNavButtonSelector,
  closeMobileMenuButtonSelector,
  globalSearchAppId,
  openMobileMenuButtonSelector,
  sustainabilityLinkClassName,
  headerHeightMobileCSSVariable,
  menuWrapSelector,
  menuSectionSelector,
  menuL1ItemsSelector,
  seeAllL1LinksSelector,
  menuL1ButtonClassName,
  menuL2ButtonSelector,
  menuL2ListSelector,
  menuL2ItemsSelector,
  menuL3ItemsSelector,
  menuWrapperSimplifiedSelector,
  mobileHeaderHeightWithGlobalSearch,
  mobileMetabarSelector,
  promoCtaSelector,
  b2bB2cToggleMobileSelector,
  loaderClassName,
  logoSELinkSelector,
  mobileBlockWrapSelector,
  nestedCountrySelectorSelector,
  startChinaLink,
  b2cPageClassName,
  myDocsSelectorMobile,
  maxNumberInCounter,
  stringValueFormaxNumberInCounter,
  productPartCartCookie,
  infixB2C,
  documentPartCartCookie,
  countAttribute,
  countAttributeMobile,
  updateCounter,
  updateCounterMobile,
  shoppingCart,
  shoppingCartAPC,
  shoppingCartMobile,
  expandedMenuClassName } from './config';

const shoppingCartCookie = {
  name: null,
  value: null,
};
const documentCookie = {
  name: null,
  value: null,
};

let headerElement = null;
let menuWrapElement = null;
let closeMobileMenuButtonElement = null;
let openMobileMenuButtonElement = null;
let menuL1ItemsElements = null;
let mobileMetabarElement = null;
let b2bB2cToggleMobile = null;
let sustainabilityLink = null;
let shoppingCartsElements = null;
let shoppingCartsElementsAPC = null;
let shoppingCartsElementsMobile = null;
let myDocsElementMobile = null;

const getNodes = (headerCommonPartElement) => {
  headerElement = document.querySelector(`.${headerClassName}`);
  menuWrapElement = headerCommonPartElement.querySelector(menuWrapSelector);
  closeMobileMenuButtonElement = headerCommonPartElement.querySelector(closeMobileMenuButtonSelector);
  openMobileMenuButtonElement = headerCommonPartElement.querySelector(openMobileMenuButtonSelector);
  menuL1ItemsElements = headerCommonPartElement.querySelectorAll(menuL1ItemsSelector);
  mobileMetabarElement = headerCommonPartElement.querySelector(mobileMetabarSelector);
  b2bB2cToggleMobile = document.querySelector(b2bB2cToggleMobileSelector);
  sustainabilityLink = headerCommonPartElement.querySelector(`.${sustainabilityLinkClassName}`);
  if (headerCommonPartElement) {
    shoppingCartsElements = headerCommonPartElement.querySelectorAll(shoppingCart);
    shoppingCartsElementsAPC = headerCommonPartElement.querySelectorAll(shoppingCartAPC);
    shoppingCartsElementsMobile = headerCommonPartElement.querySelectorAll(shoppingCartMobile);
    myDocsElementMobile = headerCommonPartElement.querySelectorAll(myDocsSelectorMobile);
  }
};

const getCookiesFullName = (element, { countryCode, languageCode }) => {
  // eslint-disable-next-line no-param-reassign
  countryCode = countryCode.toUpperCase();
  const isB2C = element.classList.contains(b2cPageClassName);
  shoppingCartCookie.name = `${productPartCartCookie}${
    isB2C ? infixB2C : ''}${languageCode}_${countryCode}`;
  documentCookie.name = `${documentPartCartCookie}${languageCode}_${countryCode}`;
};

const capNumberValue = (cookieValue) => ((cookieValue >= maxNumberInCounter)
  ? stringValueFormaxNumberInCounter
  : cookieValue);

export const setNumberValue = (items, cookie) => {
  // use this, in reason that cookies API no supported by Safari
  const currentCookieValue = cookie.name && getCookie(cookie.name);
  if (items && cookie.value !== currentCookieValue) {
    forEach(
      items,
      (item) => {
        const counterValue = cookie.name && capNumberValue(currentCookieValue);
        /* If update data-attr value with using dataset IE doesn't understand that value was updated,
        because that for updating data-attr value we use setAttribute here */
        item.setAttribute(countAttribute, counterValue || 0);
        const a11yLabelForCart = item.querySelector(updateCounter);
        if (a11yLabelForCart) {
          a11yLabelForCart.textContent = counterValue;
          a11yLabelForCart.setAttribute('aria-label', counterValue || 0);
        }
        cookie.value = currentCookieValue;
      },
    );
  }
};

export const setNumberValueMobile = (items, cookie) => {
  const currentCookieValue = cookie.name && getCookie(cookie.name);
  if (items) {
    forEach(
      items,
      (item) => {
        const counterValue = cookie.name && capNumberValue(currentCookieValue);
        item.setAttribute(countAttributeMobile, counterValue || 0);
        const a11yLabelForCart = item.querySelector(updateCounterMobile);
        if (a11yLabelForCart) {
          a11yLabelForCart.textContent = counterValue;
          a11yLabelForCart.setAttribute('aria-label', counterValue || 0);
        }
        cookie.value = currentCookieValue;
      },
    );
  }
};

const initCounter = () => {
  setNumberValue(shoppingCartsElements, shoppingCartCookie);
  setNumberValue(shoppingCartsElementsAPC, shoppingCartCookie);
  setNumberValueMobile(shoppingCartsElementsMobile, shoppingCartCookie);
  setNumberValueMobile(myDocsElementMobile, documentCookie);
};

export default shell.registerPartial($this, ({
  addEventListener,
  configs,
  mount,
  subscribeToMessage,
  log,
  settings,
}) => {
  const closeMenu = ({ target, forcedClose, code }) => {
    if (
      (!target.closest(menuWrapSelector) && !target.closest(`.${countrySelectorSEClassName}`))
      || forcedClose
      || code === configs.keyNames.escape) {
      // eslint-disable-next-line no-use-before-define
      forEach(
        menuL1ItemsElements,
        (element) => {
          element.classList.remove(css.classNames.close);
          element.classList.remove(css.classNames.open);
          element.querySelector(menuSectionSelector)?.classList.add(css.classNames.close);
        },
      );
      menuWrapElement.classList.remove(css.classNames.open);
      headerElement.classList.remove(expandedMenuClassName);
      mobileMetabarElement.classList.remove(configs.css.classNames.hide);
      b2bB2cToggleMobile?.classList.remove(configs.css.classNames.hide);

      if (isMobileBreakpoint() && (
        target.classList.contains(menuL1ButtonClassName)
        || target.closest(mobileMetabarSelector)
        || target.closest(b2bB2cToggleMobileSelector))
      ) {
        clearAllBodyScrollLocks();
        menuWrapElement.classList.add(css.classNames.close);
        headerElement.classList.remove(expandedMenuClassName);
        openMobileMenuButtonElement.focus();
      }

      window.removeEventListener('click', closeMenu);
      const menuL1Item = target.closest(menuL1ItemsSelector);
      if (isUsingKeyboard()) {
        menuL1Item?.querySelector(`.${menuL1ButtonClassName}`).setAttribute('tabIndex', '0');
      }
      if (code === configs.keyNames) {
        menuL1Item?.querySelector(`.${menuL1ButtonClassName}`).focus();
      }

      menuL1Item?.querySelector(`.${menuL1ButtonClassName}`).setAttribute(ariaExpandedAttribute, false);
    }
  };

  const onMenuSectionKeyDown = (e) => {
    const mobileLinks = !e.target.closest(menuL3ItemsSelector)
      ? [...e.currentTarget.querySelectorAll(`${seeAllL1LinksSelector} a`),
        ...e.currentTarget.querySelectorAll(`${menuL2ButtonSelector}, ${menuL2ItemsSelector} > a`)]
      : [...e.target.closest(menuL3ItemsSelector).children];
    const links = !isMobileBreakpoint()
      ? [...e.currentTarget.querySelectorAll('a:not(.extra-links-item)')]
      : mobileLinks;
    const buttons = !isMobileBreakpoint()
      ? [...e.currentTarget.querySelectorAll(menuNavButtonSelector)]
      : [...e.currentTarget.querySelectorAll(menuNavButtonSelector),
        ...mobileMetabarElement.children].filter((button) => !button.classList.contains(css.classNames.hide));

    const currentButtonIndex = buttons.indexOf(e.target);
    const currentLink = e.target;
    const currentLinkIndex = links.indexOf(e.target);

    const [firstLink] = [first(links)];
    const [nextLink] = [links[currentLinkIndex + 1]];
    const [previousButton, previousLink] = [buttons[currentButtonIndex - 1], links[currentLinkIndex - 1]];
    const [lastButton, lastLink] = [last(buttons), last(links)];

    if (e.shiftKey && e.code === configs.keyNames.tab && firstLink === currentLink) {
      if (!isMobileBreakpoint()) {
        e.preventDefault();
        lastLink.focus();
      }
    }
    if (!e.shiftKey && e.code === configs.keyNames.tab && lastLink === currentLink) {
      if (!isMobileBreakpoint()) {
        e.preventDefault();
        firstLink.focus();
      }
    }
    if (e.key === configs.keyNames.home) {
      if (!isMobileBreakpoint()) {
        firstLink.focus();
      }
      e.preventDefault();
    }
    if (e.key === configs.keyNames.end) {
      if (!isMobileBreakpoint()) {
        lastLink.focus();
      }
      e.preventDefault();
    }

    if (e.key === configs.keyNames.arrowUp || (e.code === configs.keyNames.arrowLeft && isMobileBreakpoint())) {
      (previousLink ?? lastLink).focus();
      e.preventDefault();
    }

    if (e.key === configs.keyNames.arrowDown || (e.code === configs.keyNames.arrowRight && isMobileBreakpoint())) {
      (nextLink ?? firstLink).focus();
      e.preventDefault();
    }

    if (e.code === configs.keyNames.arrowLeft && !isMobileBreakpoint()) {
      (previousButton ?? lastButton)?.focus();
    }

    if (e.code === configs.keyNames.arrowRight && !isMobileBreakpoint()) {
      const menuL2Item = document.querySelectorAll('.menu-item');
      forEach(menuL2Item, (element) => {
        if (!element.classList.contains('hide')) { element.querySelector('a').focus(); }
      });
    }
  };

  const onMenuButtonKeyDown = (e) => {
    const buttons = !isMobileBreakpoint()
      ? [...e.currentTarget.querySelectorAll(`.${menuL1ButtonClassName}`)]
      : filter(
        [
          ...e.currentTarget.querySelectorAll(`.${menuL1ButtonClassName}`),
          ...mobileMetabarElement.children,
        ],
        ({ classList }) => !classList.contains(css.classNames.hide),
      );
    const currentButtonIndex = buttons.indexOf(e.target);
    const firstButton = buttons[0];
    const lastButton = last(buttons);
    const nextButton = buttons[currentButtonIndex + 1];
    const previousButton = buttons[currentButtonIndex - 1];

    if (e.key === configs.keyNames.arrowRight || (e.code === configs.keyNames.arrowDown && isMobileBreakpoint())) {
      e.preventDefault();
      nextButton && nextButton.focus();
      !nextButton && firstButton.focus();
    }

    if (e.key === configs.keyNames.arrowLeft || (e.code === configs.keyNames.arrowUp && isMobileBreakpoint())) {
      e.preventDefault();
      previousButton && previousButton.focus();
      !previousButton && lastButton.focus();
    }
  };

  const focusFirstLink = (menuItem) => {
    const menuSection = menuItem.querySelector(menuSectionSelector);
    menuSection.querySelector('a').focus();
  };

  const onEscCloseMenu = (e) => {
    if (e.key === configs.keyNames.escape) {
      closeMenu(e);
    }
  };

  const userType = getCookie(configs.sso.userTypeCookieName);

  const filterMenuByUserType = (menuElement) => {
    log.info('Filtering product menu by user type: %s', userType);
    forEach(
      menuElement
        .querySelectorAll(`[data-profession-codes]:not([data-profession-codes*=${userType}])`),
      (link) => link.parentNode.removeChild(link),
    );
  };

  const initializeMenuItem = (menuItem) => {
    const productsMenuSelector = '.mm-main-list-products';

    const productsMenu = menuItem.querySelector(productsMenuSelector);
    if (productsMenu && userType && userType !== configs.sso.nonAuthorizedUserType) {
      filterMenuByUserType(productsMenu);
    }
  };

  const showMenuL1ItemOnClick = (menuItem, showLoader) => {
    const placeholderElement = menuItem.querySelector(nestedCountrySelectorSelector);
    if (!placeholderElement) return Promise.resolve();

    placeholderElement.classList.toggle(loaderClassName, showLoader);
    const { path } = placeholderElement.dataset;
    return ajax.fetchDeferralTo(path, placeholderElement)
      .then(() => placeholderElement.classList.remove(loaderClassName));
  };

  const loadDeferredMenuItems = async (menuItem, showLoader) => {
    if (menuItem.dataset.initialized) return;
    menuItem.dataset.initialized = true;

    try {
      await showMenuL1ItemOnClick(menuItem, showLoader);
      initializeMenuItem(menuItem);
    } catch {
      menuItem.dataset.initialized = false;
    }
  };

  const toggleL1Items = async (menuItem = null) => {
    // close l1 menu
    if (menuItem?.classList.contains(css.classNames.open)) {
      forEach(
        menuL1ItemsElements,
        ({ classList }) => {
          classList.remove(css.classNames.open, css.classNames.close);
        },
      );
      menuWrapElement.classList.remove(css.classNames.open);
      menuItem.querySelector(menuSectionSelector)?.classList.add(css.classNames.close);
      menuItem.querySelector(`.${menuL1ButtonClassName}`).setAttribute(ariaExpandedAttribute, false);
      mobileMetabarElement.classList.remove(configs.css.classNames.hide);
      b2bB2cToggleMobile?.classList.remove(configs.css.classNames.hide);
      if (!isMobileBreakpoint()) headerElement.classList.remove(expandedMenuClassName);
    } else if (menuItem) { // open l1 menu
      forEach(
        menuL1ItemsElements,
        (element) => {
          if (element !== menuItem) {
            element.classList.add(css.classNames.close);
            element.classList.remove(css.classNames.open);
            element.querySelector(menuSectionSelector)?.classList.add(css.classNames.close);
            element.querySelector(`.${menuL1ButtonClassName}`)?.setAttribute(ariaExpandedAttribute, false);
          }
        },
      );
      menuItem.classList.add(css.classNames.open);
      menuItem.querySelector(menuSectionSelector)?.classList.remove(css.classNames.close);
      menuItem.querySelector(`.${menuL1ButtonClassName}`).setAttribute(ariaExpandedAttribute, true);
      menuWrapElement.classList.add(css.classNames.open);
      headerElement.classList.add(expandedMenuClassName);
      mobileMetabarElement.classList.add(configs.css.classNames.hide);
      b2bB2cToggleMobile?.classList.add(configs.css.classNames.hide);
      (!isMobileBreakpoint()) && addEventListener(window, 'click', closeMenu);
      if (isUsingKeyboard()) {
        addEventListener(menuItem.querySelector(menuSectionSelector), 'keydown', onMenuSectionKeyDown);
      }
    } else {
      menuWrapElement.classList.remove(css.classNames.open);
      headerElement.classList.remove(expandedMenuClassName);
    }
  };

  const toggleL2Items = (menuItem = null) => {
    // eslint-disable-next-line no-shadow
    const toggleL2menuItemsVisibility = (item, menuItem, force = false) => {
      if (menuItem !== item) item.classList.toggle(css.classNames.close, force);
    };
    // close l2 menu
    if (menuItem?.classList.contains(css.classNames.open)) {
      const l1Item = menuItem.closest(menuL1ItemsSelector);
      l1Item.querySelector(`.${menuL1ButtonClassName}`).classList.remove(css.classNames.close);
      l1Item.querySelector(seeAllL1LinksSelector).classList.remove(css.classNames.close);
      forEach(
        menuWrapElement.querySelectorAll(promoCtaSelector),
        ({ classList }) => classList.remove(css.classNames.close),
      );
      forEach(
        menuItem
          .closest(menuL2ListSelector)
          .querySelectorAll(menuL2ItemsSelector),
        (item) => toggleL2menuItemsVisibility(item, menuItem),
      );
      menuItem.closest(menuL2ItemsSelector).querySelector(menuL3ItemsSelector).classList.add(css.classNames.close);
      menuItem.classList.remove(css.classNames.open);
    } else if (menuItem) { // open l2 menu
      const l1Item = menuItem.closest(menuL1ItemsSelector);
      l1Item.querySelector(`.${menuL1ButtonClassName}`).classList.add(css.classNames.close);
      l1Item.querySelector(seeAllL1LinksSelector).classList.add(css.classNames.close);
      forEach(
        menuWrapElement.querySelectorAll(promoCtaSelector),
        ({ classList }) => classList.add(css.classNames.close),
      );
      forEach(
        menuItem
          .closest(menuL2ListSelector)
          .querySelectorAll(menuL2ItemsSelector),
        (item) => toggleL2menuItemsVisibility(item, menuItem, true),
      );
      menuItem.closest(menuL2ItemsSelector).querySelector(menuL3ItemsSelector).classList.remove(css.classNames.close);
      menuItem.classList.add(css.classNames.open);
    }
  };
  const closeMobileMenu = (e) => {
    if (
      e.code === configs.keyNames.enter
      || e.code === configs.keyNames.space
      || e.code === configs.keyNames.escape
      || e.type === 'click'
    ) {
      clearAllBodyScrollLocks();
      menuWrapElement.classList.add(css.classNames.close);
      headerElement.classList.remove(expandedMenuClassName);
      isUsingKeyboard() && openMobileMenuButtonElement.focus();
      e.preventDefault();
    }
  };

  const openMobileMenu = (e) => {
    if (
      e.code === configs.keyNames.enter
      || e.code === configs.keyNames.space
      || e.type === 'click'
    ) {
      disableBodyScroll(menuWrapElement);
      menuWrapElement.classList.remove(css.classNames.close);
      headerElement.classList.add(expandedMenuClassName);
      isUsingKeyboard() && closeMobileMenuButtonElement.focus();
      e.preventDefault();
    }
  };

  const toggleMenuItemByClick = ({ target }) => {
    if (target.closest(`.${menuL1ButtonClassName}`)) {
      toggleL1Items(target.closest(menuL1ItemsSelector));
    }
    if (target.closest(menuL2ButtonSelector)) {
      toggleL2Items(target.closest(menuL2ItemsSelector));
    }
  };

  const toggleMenuItemByKeyboard = (e) => {
    const menuL1Item = e.target.closest(menuL1ItemsSelector);
    if (e.target.closest(`.${menuL1ButtonClassName}`)) {
      onEscCloseMenu(e);
      if (e.code === configs.keyNames.enter || e.code === configs.keyNames.space) {
        e.preventDefault();
        toggleL1Items(menuL1Item);
        focusFirstLink(menuL1Item);
        menuL1Item?.querySelector(`.${menuL1ButtonClassName}`).setAttribute(ariaExpandedAttribute, true);
      }
      isMobileBreakpoint() && e.target.closest(mobileMetabarSelector) && onMenuButtonKeyDown(e);
    }
  };

  const loadAndOpenMenuItemOnClick = async (e) => {
    if (e.type === 'keydown') {
      toggleMenuItemByKeyboard(e);
    } else toggleMenuItemByClick(e);
    const loadPromises = map(menuL1ItemsElements, (menuItem) => loadDeferredMenuItems(menuItem, true));
    await Promise.all(loadPromises);
    appendUTMParametersHeader();
  };

  const loadAndOpenMenuItemOnMouseHover = (e) => {
    if (!e.target.closest(menuL1ItemsSelector)?.classList.contains(css.classNames.open)) {
      loadAndOpenMenuItemOnClick(e);
    }
  };

  const logoElement = document.querySelector(logoSELinkSelector);
  const mobileBlockWrap = document.querySelector(mobileBlockWrapSelector);
  if (trimEnd(document.location.href, '/') === trimEnd(logoElement?.href, '/')
    || document.location.href === startChinaLink) {
    logoElement.classList.add(css.classNames.disabled);
    logoElement.setAttribute('tabIndex', '-1');
    mobileBlockWrap.setAttribute('tabIndex', '-1');
  }

  const addListeners = (element) => {
    addEventListener(closeMobileMenuButtonElement, 'click', closeMobileMenu);
    addEventListener(closeMobileMenuButtonElement, 'keydown', closeMobileMenu);
    addEventListener(openMobileMenuButtonElement, 'click', openMobileMenu);
    addEventListener(openMobileMenuButtonElement, 'keydown', openMobileMenu);
    addEventListener(menuWrapElement, 'click', loadAndOpenMenuItemOnClick);
    addEventListener(menuWrapElement, 'keydown', loadAndOpenMenuItemOnClick);
    isHeaderVersion8()
      && (!isMobileBreakpoint())
      && addEventListener(menuWrapElement, 'click', loadAndOpenMenuItemOnMouseHover);
    addEventListener(window, 'keydown', onEscCloseMenu);
    addEventListener(window, 'resize', clearAllBodyScrollLocks);
    subscribeToMessage(messages.userClickedMegaMenuCloseButtonOnDesktop, ({ selector }) => {
      // It would be better to pass the original click event from the close button. However, we cannot do that.
      // The current `publishMessage` and `subscribeToMessage` implementations cannot disallow to pass complex
      // objects through this channel. Therefore, we just pass the selector and then build a surrogate event object.
      const target = element.querySelector(selector);
      closeMenu({ target, forcedClose: true });
    });
  };

  const overwriteHeaderHeightMobileCSSVariable = () => document
    .documentElement
    .style
    .setProperty(headerHeightMobileCSSVariable, `${mobileHeaderHeightWithGlobalSearch}px`);

  const setSearchPlaceholder = () => {
    const searchInput = document.querySelector('.search-bar__input');
    const searchInputWrapper = document.querySelector('.search-menu-wrapper');
    if (searchInputWrapper?.dataset?.searchPlaceholder !== undefined) {
      if (searchInput) {
        searchInput.placeholder = searchInputWrapper.dataset.searchPlaceholder;
      }
    }
  };

  mount((element) => {
    if (
      element.querySelector(`#${globalSearchAppId}`)
      || element.querySelector(menuWrapperSimplifiedSelector)
    ) overwriteHeaderHeightMobileCSSVariable();

    getNodes(element);
    addListeners(element);

    getCookiesFullName(element, settings.page);
    redefineTargetAttributeOfSustainabilityLink(sustainabilityLink);
    setInterval(initCounter, delays.s5);
    setSearchPlaceholder();
  });
});
